import * as React from "react"
import Navigation from "../components/navigation-intern"
import Hero from "../components/hero-intern"
import { Link } from "gatsby"

import GraphicWhoWeAre from "../images/graphic_payment_processor.jpg"
import GraphicDeveloper from "../images/graphic_developer.jpg"
import GraphicSocialising from "../images/graphic_socializing.png"
import GraphicCareerGrowth from "../images/graphic_day_in_the_life.jpg"
import GraphicCoachingAndMentoring from "../images/graphic_what_you_will_learn.jpg"

import ResponsiveContainer from "../components/responsive-container"
import { faCheck, faChevronDown } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Disclosure } from "@headlessui/react"

import {
  UkFlexibleHolidayLeave,
  UsaFlexibleHolidayLeave,
  CaFlexibleHolidayLeave,
  RoiFlexibleHolidayLeave,
} from "../components/flexible-holiday-leave"

import {
  UkHealthInsurance,
  UsaHealthInsurance,
  UsaHealthSavings,
  UsaAdditionalHealth,
  CaHealthInsurance,
  RoiHealthInsurance,
} from "../components/free-health-insurance"

import {
  UkRemoteWorkingAllowance,
  UsaRemoteWorkingAllowance,
  CaRemoteWorkingAllowance,
  RoiRemoteWorkingAllowance,
} from "../components/remote-working-allowance"

import {
  UkGroupPensionScheme,
  UsaGroupPensionScheme,
  CaGroupPensionScheme,
  RoiGroupPensionScheme,
} from "../components/group-pension-scheme"

import { AnnualBonusPlan } from "../components/annual-bonus"

import { UkReferralPolicy, UsaReferralPolicy, CaReferralPolicy, RoiReferralPolicy } from "../components/referral-policy"

import { PaidVolunteering } from "../components/paid-volunteering"

const benefitsTable = {
  uk: {
    title: "United Kingdom",
    items: [
      <UkFlexibleHolidayLeave />,
      <UkHealthInsurance />,
      <UkRemoteWorkingAllowance />,
      <UkGroupPensionScheme />,
      <AnnualBonusPlan />,
      <UkReferralPolicy />,
      <PaidVolunteering />,
    ],
  },
  usa: {
    title: "United States",
    items: [
      <UsaFlexibleHolidayLeave />,
      <UsaRemoteWorkingAllowance />,
      <UsaGroupPensionScheme />,
      <AnnualBonusPlan />,
      <UsaHealthInsurance />,
      <PaidVolunteering />,
      <UsaHealthSavings />,
      <UsaAdditionalHealth />,
      <UsaReferralPolicy />,
    ],
  },
  ca: {
    title: "Canada",
    items: [
      <CaFlexibleHolidayLeave />,
      <AnnualBonusPlan />,
      <CaRemoteWorkingAllowance />,
      <CaGroupPensionScheme />,
      <CaHealthInsurance />,
      <PaidVolunteering />,
      <CaReferralPolicy />,
    ],
  },
  roi: {
    title: "Republic of Ireland",
    items: [
      <RoiFlexibleHolidayLeave />,
      <RoiGroupPensionScheme />,
      <AnnualBonusPlan />,
      <RoiRemoteWorkingAllowance />,
      <PaidVolunteering />,
      <RoiHealthInsurance />,
      <RoiReferralPolicy />,
    ],
  },
}

const customers = [
  {
    name: "Electric Era",
    title:
      "We support former SpaceX engineers in addressing climate change and sustainability by enabling their EV charging stations to accept unattended card payments.",
  },
  {
    name: "Rivian",
    title:
      "By utilizing Payroc's SDKs, Rivian, the supplier of electric delivery vans for Amazon, can deploy their charging stations with uninterrupted, consistently available payment processing.",
  },
  {
    name: "Dispension",
    title:
      "By offering self-service beer kiosks, such as those found at the L.A. Dodgers stadium, Payroc's payment solutions empower customers to skip the lines for beverages.",
  },
  {
    name: "Ascend",
    title:
      "Payroc assists Ascend in rounding up payments for charitable organizations into tax-deductible donations, which can be made on a recurring monthly basis, leveraging our versatile API suite.",
  },
]

const values = [
  {
    title: "Autonomy with Accountability",
    description:
      "Have a bias for action, learn as you go, iterate.  Make commitments, progress and results visible to everyone; review regularly.",
  },
  {
    title: "Curiosity",
    description: "Constant noticing and experimenting, challenging the status quo; fail fast and promote a failure-friendly environment.",
  },
  {
    title: "Courage",
    description: "Our environment is complex. The only way to understand a complex system is to interact with it; lean in.",
  },
  {
    title: "Simplicity",
    description:
      "Ask ourselves, what’s the smallest/simplest amount of policy required to protect us while preserving the flexibility to learn and act with judgment?",
  },
  {
    title: "Humility",
    description: "It’s OK to check-in, check-up, reach-out, and ask for help.",
  },
  {
    title: "Respect",
    description: "Understand the implications of our actions on upstream/downstream stakeholders; be a good neighbour.",
  },
  {
    title: "Openness",
    description: "Share thinking, decision making, and process, not only outcomes.",
  },
  {
    title: "Trust",
    description:
      "Be honest, sincere, reliable, vulnerable, communicate openly, discuss fears, admit mistakes, cultivate a blameless environment.",
  },
]

const benefits = [
  {
    title: "Competitive Salary",
    description:
      "We offer our interns a base salary of £25,000 per year.  We review this mid way through your internship and adjust on performance.",
  },
  {
    title: "Flexible Working",
    description:
      "Early starter? No problem!  Find you're more productive later in the evening?  You work with your manager to set your own hours and working patterns.  We are fully remote, but recognise you may need to get out of the house from time to time.  You can claim back co-working space as needed.",
  },
  {
    title: "Flexible Leave",
    description:
      "We don't cap how many days holiday you can take, so if you need time off to recharge, take it.  All we ask in return is for you to have a healthy discontent for delays and have a drive and desire to achieve results",
  },
  {
    title: "Healthcare",
    description:
      "There is nothing more important than the health and well-being of our team.  We offer healthcare from the day you start, along with dental and mental health benefits.  Vitality is our provider of choice, who also offer remote GP appointments as needed.",
  },
]

// markup
const IndexPage: React.FC = (): React.ReactElement => {
  return (
    <>
      <main className="light min-h-full  min-w-[350px]">
        <div className="bg-white dark:bg-deep-navy-800 h-full min-h-full flex flex-col font-sans">
          <section className="flex">
            <Navigation />
          </section>

          <Hero />

          {/* Who are Payroc */}
          <section className="mt-24 mb-8 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 lg:right-1/3 lg:left-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col flex-col-reverse lg:flex-row items-center justify-between my-8">
                <div className="lg:max-w-lg xl:max-w-xl md:pr-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">GET TO KNOW US</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">Who are Payroc?</h1>
                  <div className="font-light my-8">
                    We are a fintech, specialising in payments technology that powers some of the most innovative companies in the world. We
                    are headquartered in Chicago, USA, however have people in 10+ countries across Europe and North America.
                  </div>
                  <div className="font-light my-8">
                    Together, we create solutions for over 150,000 merchants, across 45 markets, processing transactions totalling nearly
                    $100 billion annually.
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#internship-positions"
                        className="block text-center bg-lavender hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        Apply today
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex lg:py-8">
                  <img src={GraphicWhoWeAre} alt="Get to know us" />
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          {/* What you will learn */}
          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="items-center my-24">
                <div className="">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">OUR PARTNERS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">We power some really innovative technology</h1>
                  <div className="font-light ">
                    We help our customers in their efforts to create a more eco-friendly environment, support charitable causes, or bring
                    innovation to the entertainment sector.
                  </div>
                  <div className="flex w-full my-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 w-full">
                      {customers.map((c) => (
                        <div className="flex bg-white p-4 rounded-md shadow-xl space-x-4 justify-around group cursor-pointer">
                          <div className="flex-col flex-1 justify-between items-center">
                            <p>
                              <span className="font-rocgrotesk-bold text-lavender">{c.name}</span>
                            </p>
                            <p className="font-rocgrotesk-light tracking-tight my-4">{c.title}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          {/* What you will do */}
          <section className="my-8 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 lg:left-1/3 lg:right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col lg:flex-row items-center justify-between my-8">
                <div className="flex lg:py-8">
                  <img src={GraphicDeveloper} alt="We solve problems" />
                </div>
                <div className="lg:max-w-lg xl:max-w-xl md:pl-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">WHATS OUR TECH</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">We work with the best</h1>
                  <div className="font-light my-8">
                    <p className="my-8">
                      We leverage modern .NET and Java stacks to build our APIs, with Typescript and React driving our front-ends.
                    </p>
                    <p className="my-8">
                      Our tech backbone is powered by some of the biggest names in the business, like Google, Amazon, Microsoft, Cloudflare,
                      and Apple. It's like having the giants of tech on your side!
                    </p>
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#internship-positions"
                        className="block text-center bg-lavender hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        Apply today
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          {/* What you will learn */}
          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="items-center my-24">
                <div className="">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">CULTURE AND VALUES</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">Our Ethos</h1>
                  <div className="font-light my-2">
                    At Payroc it's OK to be human! Our ethos allows the team to be open, stay curious, and to express themselves freely.
                  </div>
                  <div className="font-light my-2">
                    We work hard to create a culture of creativity and innovation where you can experience a truly contagious spirit of
                    enthusiasm, pride, and relentless service. Payroc’s core values are the foundation of our success!
                  </div>
                  <div className="flex w-full my-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 w-full">
                      {values.map((c) => (
                        <div className="flex bg-white p-4 rounded-md shadow-xl space-x-4 justify-around group cursor-pointer">
                          <div className="flex-col flex-1 justify-between items-center">
                            <p>
                              <span className="font-rocgrotesk-bold text-lavender">{c.title}</span>
                            </p>
                            <p className="font-rocgrotesk-light tracking-tight my-8">{c.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 lg:left-1/3 lg:right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col lg:flex-row items-center justify-between my-8">
                <div className="flex lg:py-8">
                  <img src={GraphicSocialising} alt="Its ok" />
                </div>
                <div className="lg:max-w-lg xl:max-w-xl md:pl-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">SOCIALISING</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">We embrace fun</h1>
                  <div className="font-light my-8">
                    <p className="my-4">
                      We’ve got remote-first working nailed. It’s a different experience developing your career in a distributed team, but
                      our remote working and socialising strategy help our team thrive.
                    </p>
                    <p className="my-4">
                      From spontaneous team coffee chats, informal lunches or dinners, virtual events that encompass multiple teams, to our
                      more extensive Technology department gatherings, you'll have ample opportunities to connect and have fun with your
                      colleagues.
                    </p>
                    <p className="my-4">
                      Our biannual hackathons stand out as the highlights of our social calendar. Over 150 team members from across the
                      globe convene in Belfast for three days of innovative idea hacking, along with team gatherings, dinners, and
                      socializing, offering a relaxed yet invigorating experience.
                    </p>
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#internship-positions"
                        className="block text-center bg-lavender hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        Apply today
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="items-center my-24">
                <div className="">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">SALARY AND BENEFITS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">What we offer our Interns</h1>
                  <div className="font-light my-8 lg:max-w-2xl xl:max-w-3xl">
                    If you are successful, we offer a competitive salary along with a range of benefits, including healthcare, flexible
                    leave, and paid co-working space.
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
                    {benefits.map((b) => (
                      <div className="flex bg-white p-4 rounded-md shadow-xl space-x-4 justify-around group cursor-pointer">
                        <div className="flex-col flex-1 justify-between items-center">
                          <p>
                            <span className="font-rocgrotesk-bold text-lavender">{b.title}</span>
                          </p>
                          <p className="font-rocgrotesk-light tracking-tight my-8">{b.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 lg:right-1/3 lg:left-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col flex-col-reverse lg:flex-row items-center justify-between my-8">
                <div className="lg:max-w-lg xl:max-w-xl md:pr-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">WHAT WILL YOU DO</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">A day in the life of an Intern</h1>
                  <div className="font-light my-8">
                    <div className="font-light my-8">
                      Working in one of our fast-fix teams, your days will be varied as you use the skills you've learned in a professional
                      software engineering environment.
                    </div>
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      You will take requirements and work with our Product Managers and Architects to understand them, design and then
                      develop solutions to meet them.
                    </div>
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      Once developed and committed to GitHub, you will work with seasoned engineers to review the change, thus helping to
                      improve the design and to refine your skills.
                    </div>
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      Automatically deploy your code to one of our test environments and collaborate with one of our Test Engineers to make
                      sure it functions correctly, using automated tests you develop using tools like SpecFlow & Playwright.
                    </div>
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      Partner with our Site Reliability team to make sure your feature is monitored in case something goes wrong!
                    </div>
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      Deploy your code to Production and feel proud that you have made someone’s day easier with that new feature, or fixed
                      a problem that was frustrating them.
                    </div>
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#internship-positions"
                        className="block text-center bg-lavender hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        Apply today
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex lg:py-8">
                  <img src={GraphicCareerGrowth} alt="Develop your career" />
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 lg:left-1/3 lg:right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col lg:flex-row items-center justify-between my-8">
                <div className="flex lg:py-8">
                  <img src={GraphicCoachingAndMentoring} alt="Its ok" />
                </div>
                <div className="lg:max-w-lg xl:max-w-xl md:pl-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">OUR PROMISE</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">Experience to build your career</h1>
                  <div className="font-light my-8">
                    <p className="my-4">
                      An internship with Payroc means you get to work and learn from some of the best engineers across the Globe, building
                      new, exciting payments technology.
                    </p>
                    <div className="my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      <span className="font-bold">Mentorship:</span> Receive mentorship from seasoned professionals, aiding your growth and
                      development within the software engineering discipline.
                    </div>
                    <div className="my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      <span className="font-bold">Networking:</span> Expand your professional network, interacting with a diverse and
                      inclusive team of talented individuals.
                    </div>
                    <div className="my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-lavender mr-4 float-left" />
                      <span className="font-bold">Career Advancement:</span> Leverage this opportunity as a stepping stone for your career,
                      with potential full-time opportunities post-internship.
                    </div>
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#internship-positions"
                        className="block text-center bg-lavender hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        Apply today
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative py-8">
              <div className="flex flex-col flex-shrink-0 lg:flex-row justify-between my-8">
                <div className="basis-1/2 md:pr-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">FAQS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">Have a question?</h1>
                  <div className="font-light my-8 lg:max-w-2xl xl:max-w-3xl">
                    <p className="my-8">You can find some of the most commonly asked questions from previous interns answered here.</p>
                    <p className="my-8">
                      We're happy to answer any further questions you may have. Drop us an email at{" "}
                      <a href="mailto:techrecruitement@payroc.com" className="text-lavender underline">
                        techrecruitment@payroc.com
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:py-8 basis-1/2">
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-lavender">
                          <span>What is the interview process?</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-lavender ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          Our interview process is a simple, single stage, and typically only takes 1-2 hours.
                          <ol className="mt-4 list-decimal list-inside">
                            <li>Informal chat focusing on interpersonal skills and approach.</li>
                            <li>More technical questions helping us gauge your technical understanding and ability.</li>
                          </ol>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-lavender">
                          <span>I'm not sure about being fully remote, how does it all work?</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-lavender ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          <p className="mb-4">
                            We make sure you get the latest Macbook or Windows laptop (your choice) ahead of your start date. You also get
                            dual 27-inch monitors, keyboard, mouse and headset. On top of that, there is £500 home-office setup allowance so
                            you can be comfortable while you work.{" "}
                          </p>
                          <p className="mb-4">
                            During your first few weeks with us, we help you work through our onboarding process, connecting you with with
                            various people throughout the company, and arming you with the tools you need to be successful.{" "}
                          </p>
                          <p>
                            We rely heavily on Microsoft Teams to keep communication flowing in Payroc. Between messaging, video calls, ask
                            me anything sessions run every two weeks etc, you’ll be kept in the loop.
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-lavender">
                          <span>Will there be an opportunity for a full time role post graduation?</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-lavender ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          <p className="mb-4">
                            Payroc look out for the best talent and are always interested in new graduates. We use the internship to coach
                            and develop you with the goal of hiring post graduation.
                          </p>
                          <p>
                            This of-course depends on you; how you approach the internship and life at Payroc. As a guide, several of our
                            previous interns have received offers subject to gradution.
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-lavender">
                          <span>I have another question about working at Payroc...</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-lavender ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          We are a pretty open and honest bunch (its one of our values), so please&nbsp;
                          <a href="mailto:techrecruitment@payroc.com" className="text-lavender underline">
                            ask us anything
                          </a>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col flex-shrink-0 lg:flex-row justify-center my-8">
                <div className="pb-8 text-center">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">JOIN OUR PROGRAMME</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2" id="internship-positions">
                    Apply for our internship programme today
                  </h1>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col flex-shrink-0 lg:flex-row justify-center mb-8">
                <form className="bg-white" method="POST" action="https://payroc.tech/api/apply">
                  <div className="mb-4 flex">
                    <div className="mr-2 w-1/2">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-600">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="first-name"
                        name="first-name"
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-lavender"
                        required
                      />
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-600">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="last-name"
                        name="last-name"
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-lavender"
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="degree-course" className="block text-sm font-medium text-gray-600">
                      Degree Course
                    </label>
                    <input
                      type="text"
                      id="degree-course"
                      name="degree-course"
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-lavender"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="degree-result" className="block text-sm font-medium text-gray-600">
                      Predicted Result
                    </label>
                    <input
                      type="text"
                      id="degree-result"
                      name="degree-result"
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-lavender"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-lavender"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="mobile-number" className="block text-sm font-medium text-gray-600">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      id="mobile-number"
                      name="mobile-number"
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-lavender"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="additional-data" className="block text-sm font-medium text-gray-600">
                      Supporting Information
                    </label>
                    <textarea
                      id="additional-data"
                      name="additional-data"
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-lavender"
                      rows={4}
                    ></textarea>
                  </div>
                  <div className="mt-6">
                    <button
                      type="submit"
                      className="block text-center bg-lavender hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                    >
                      Apply for our internship programme now!
                    </button>
                  </div>
                </form>
              </div>
              <div className="flex flex-col flex-shrink-0 lg:flex-row justify-center mb-8">
                <div className="flex flex-col basis-1/3">
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm text-gray-500 font-light text-left hover:text-lavender">
                          <span>Terms and conditions</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-lavender ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          <ol className="list-decimal pl-6">
                            <li className="mb-2">
                              <strong>Data Controller:</strong> The data controller in this context is Payroc WorldAccess Ltd, located at
                              1st Floor Sackville House, 143-149 Fenchurch Street, London, England, EC3M 6BL, registered in the United
                              Kingdom.
                            </li>
                            <li className="mb-2">
                              <strong>Purpose of Data Collection:</strong> We collect personal data, including first name, last name, mobile
                              number, and email address, for the sole purpose of managing the recruitment process. This includes assessing
                              qualifications, conducting interviews, and making hiring decisions.
                            </li>
                            <li className="mb-2">
                              <strong>Legal Basis:</strong> Our legal basis for processing this data is the necessity for the performance of
                              a contract to which the data subject is party (i.e., the employment contract) and the legitimate interests
                              pursued by the data controller.
                            </li>
                            <li className="mb-2">
                              <strong>Data Retention:</strong> We will retain this data for as long as necessary to fulfill the purpose for
                              which it was collected. If a candidate is not hired, their data will be securely deleted within 24 months
                              after the conclusion of the recruitment process.
                            </li>
                            <li className="mb-2">
                              <strong>Data Security:</strong> We implement appropriate technical and organizational measures to ensure the
                              security of personal data against unauthorized or unlawful processing, accidental loss, destruction, or
                              damage.
                            </li>
                            <li className="mb-2">
                              <strong>Data Sharing:</strong> Personal data collected for recruitment purposes may be shared internally with
                              relevant HR personnel and hiring managers. It will not be disclosed to third parties without the candidate's
                              explicit consent unless required by law.
                            </li>
                            <li className="mb-2">
                              <strong>Data Subject Rights:</strong> Candidates have the right to access, rectify, or erase their personal
                              data, as well as the right to object to its processing and the right to data portability. They can exercise
                              these rights by contacting <strong>legal@payroc.com</strong>.
                            </li>
                            <li className="mb-2">
                              <strong>Consent:</strong> By submitting their personal data for recruitment purposes, the candidate consents
                              to the processing of their information as described in these terms.
                            </li>
                            <li className="mb-2">
                              <strong>Automated Decision-Making:</strong> We do not use automated decision-making, including profiling, as
                              part of our recruitment process.
                            </li>
                            <li className="mb-2">
                              <strong>Complaints:</strong> If candidates believe that their data protection rights have been infringed upon,
                              they have the right to lodge a complaint with the Information Commissioner's Office (ICO) in the United
                              Kingdom.
                            </li>
                            <li>
                              <strong>Contact Information:</strong> For any inquiries or requests related to personal data processing,
                              candidates can contact <strong>legal@payroc.com</strong>.
                            </li>
                          </ol>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </ResponsiveContainer>
          </section>
        </div>
      </main>
      <footer>
        <ResponsiveContainer className="border-t border-t-gray-300 text-center p-4 md:my-16 font-rocgrotesk-light">
          Copyright &copy; Payroc 2022
        </ResponsiveContainer>
      </footer>
    </>
  )
}

export default IndexPage
