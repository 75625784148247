import * as React from "react"
import GraphicCircles from "../images/graphic_circles_580x1284.png"
import GraphicGlassdoor from "../images/graphic_glassdoor_641x635.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDesktop, faHandshakeAlt, faLaptopHouse, faMoneyCheckEdit } from "@fortawesome/pro-light-svg-icons"

import ResponsiveContainer from "./responsive-container"

const Hero: React.FC = (): React.ReactElement => {
  return (
    <section className="text-white bg-deep-navy-900 flex justify-center relative">
      <ResponsiveContainer className="flex justify-center flex-col flex-col-reverse md:flex-row my-4 lg:my-16 z-10">
        <div className="md:basis-3/5 flex flex-col justify-center md:pr-12">
          <h1 className="font-rocgrotesk-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-center sm:text-left my-4">
            Build the future of payments
          </h1>
          <div className="flex font-sans font-light text-base my-4">
            Payroc powers payments for our partners wherever and whenever they do business. We design and build software that powers
            start-ups through to multi-nationals. An internship with us equips you with the tools to achieve your goals once you graduate.
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 gap-y-8 my-4 lg:gap-x-4">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faLaptopHouse} size="2x" className="text-tangerine mr-2 float-left" />
              <span className="font-sans font-light text-base">
                Jobs in our Technology team are fully remote, with flexible working hours
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faHandshakeAlt} size="2x" className="text-tangerine mr-2 float-left" />
              <span className="font-sans font-light text-base">
                A salary of £25,000 along with several market leading benefits including flexible holiday leave
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faMoneyCheckEdit} size="2x" className="text-tangerine mr-2 float-left" />
              <span className="font-sans font-light text-base">
                £500 Home office setup budget and £50 monthly home working allowance for bills
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faDesktop} size="2x" className="text-tangerine mr-2 float-left" />
              <span className="font-sans font-light text-base">
                Choose a PC or Mac and get two 27 inch monitors, mouse, and keyboard. Everything you need to be productive.
              </span>
            </div>
          </div>
        </div>
        <div className="md:basis-2/5 flex items-center justify-center md:ml-4">
          <img src={GraphicGlassdoor} className="w-2/3 md:w-full" alt="Payroc is a top place to work" />
        </div>
      </ResponsiveContainer>
      <div className="absolute top-0 left-0 bottom-0 right-0 z-0">
        <img src={GraphicCircles} className="absolute right-0 bottom-0 h-[580px] hidden lg:block" alt="Circles" />
      </div>
    </section>
  )
}

export default Hero
